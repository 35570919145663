export default {
  name: 'manage-timecards',
  label: 'Time Cards',
  path: 'timecards',
  link: 'timecards',
  props: true,
  icon: 'table',
  description: 'View and edit worker time cards.',
  component: () => import('@/components/TabPanel.vue'),
  require: (state, getters) => {
    return state.permissions.includes('edit_punches') || state.permissions.includes('view_reports')
  },
  breadcrumbLeaf: true,
  children: [
    {
      name: 'timecard-editor',
      label: 'Edit',
      title: 'Edit Timecards',
      path: 'edit/:view?',
      component: () => import('./TimeCards.vue')
    },
    {
      name: 'shiftcard-list',
      label: 'Bulk Edit',
      title: 'Bulk Edit Timecards',
      path: 'bulk-edit/:view?',
      requireGetter: 'scheduleEnabled',
      component: () => import('./ShiftCardList.vue')
    },
    {
      name: 'manage-timecards-index',
      path: '',
      redirect: { name: 'timecard-editor' }
    }
  ]
}
